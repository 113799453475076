var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Danh sách người nhận thành công",
        visible: _vm.dialogVisible,
        width: "70%",
        "before-close": _vm.closeDialog,
        "close-on-click-modal": false,
        top: "5vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("div", { staticClass: "hr" }, [_c("hr")]),
      _c(
        "div",
        { staticClass: "table-content row-data" },
        [
          _c(
            "el-table",
            {
              ref: "multipleTable",
              attrs: {
                data: _vm.historyappsend,
                "highlight-current-row": "",
                "header-cell-style": _vm.tableHeaderColor,
                "max-height": _vm.$tableMaxHeight,
                border: "",
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  label: "STT",
                  width: "50",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "name", label: "Tên người nhận", align: "left" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "phone",
                  label: "Số điện thoại",
                  align: "left ",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "type", label: "Đối tượng", align: "center " },
              }),
              _c("el-table-column", {
                attrs: { prop: "className", label: "Học sinh", align: "left " },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }